import React from 'react';
import { render } from 'react-dom';
import Router from './components/Router';
import Page from './components/Page';

if (module.hot) {
  module.hot.accept();
}

const App = () => (
  <Page>
    <Router />
  </Page>
);

render(<App />, document.querySelector('.main'));
