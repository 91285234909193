import React, { Fragment, Suspense } from 'react';
import { render } from 'react-dom';
import {
  Switch, Route, BrowserRouter, Link
} from 'react-router-dom';
import { Transition, animated } from 'react-spring/renderprops';
import Home from '../../pages/Home';
import Header from '../Header';
import Loader from '../Loader';

const Router = () => (
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <>
        <Header />
        <section className="flexwrap">
          <main>
            <Switch>
              <Route path="/" component={Home} />
              <Route component={Home} />
            </Switch>
          </main>
        </section>
      </>
    </BrowserRouter>
  </Suspense>
);

export default Router;
