import React, { Suspense } from 'react';
import useFetch from 'fetch-suspense';
import Loader from '../components/Loader';

const FetchData = props => {
  const {
    href, method, body, render, cache
  } = props;

  const headers = new Headers({
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest'
  });

  const request = {
    method,
    mode: 'same-origin',
    credentials: 'include',
    headers,
    body,
    cache
  };

  const { data } = useFetch(href, request);

  return render(data);
};

const Fetch = props => (
  <Suspense fallback={<Loader />}>
    <FetchData {...props} />
  </Suspense>
);

export default Fetch;
