import React, { Fragment, useState } from 'react';
import HomeStyles from './Home.styled';
import VideoBlock from '../VideoBlock';
import Fetch from '../../lib/useFetch';

const Home = () => (
  <Fetch
    href="/api/home.json"
    method="GET"
    render={data => {
      if (data[0].featuredVideo) {
        return (
          <VideoBlock
            vimeoLink={data[0].featuredVideo}
            hasMargin={false}
            alignment={'center'}
            videoCaption={false}
          />
        );
      }
      return null;
    }}
  />
);

export default Home;
