const theme = {
  black: '#000000',
  grey: '#ADADAD',
  selectionHighlightColor: '#2F2223',
  selectionColor: 'white',
  lightGrey: 'rgba(0,0,0,0.1)',
  viewport: 1440, // define viewport of development macnine for accurate rem sizes
  baseline: 10
};

export default theme;
