import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated, interpolate } from 'react-spring';
import VideoPlayerControlsStyles from './VideoPlayerControls.styled';
import { IsNumeric, ConvertToRange } from '../../lib/Utils';
import { Inject } from '../../lib/Inject';

let hideUITimeout;

function VideoPlayerControls({
  isPlaying, customPlayText, customPauseText, togglePlay
}) {
  const [state, setState] = useState({
    isVisible: true,
    isVideoPlaying: isPlaying,
    isFirstTimeUnmuted: false,
    hoverProgress: 0,
    hasPaused: false
  });

  const elem = useRef();
  const props = useSpring({
    opacity: state.isVisible ? 1 : 0
  });

  useEffect(
    () => () => {
      clearTimeout(hideUITimeout);
    },
    [isPlaying]
  );

  const makeActive = () => {
    setState({ isVisible: true });
    hideUIWithDelay();
  };

  const makeInactive = () => {
    setState({ isVisible: false });
  };

  const hideUIWithDelay = () => {
    clearTimeout(hideUITimeout);
    hideUITimeout = setTimeout(makeInactive, 2000);
  };

  return (
    <VideoPlayerControlsStyles
      onMouseEnter={makeActive}
      onMouseMove={makeActive}
      onMouseLeave={makeInactive}
      onTouchStart={makeActive}
      onTouchCancel={makeInactive}
      onTouchEnd={makeInactive}
      ref={elem}
    >
      <section className={'ui-controls'}>
        <section className="chrome-controls">
          <button
            className="play-btn"
            onClick={() => {
              togglePlay();
            }}
          >
            <animated.span
              style={{
                opacity: !isPlaying ? props.opacity : 0
              }}
            >
              {customPlayText || 'Play'}
            </animated.span>
            <animated.span
              style={{
                opacity: isPlaying ? props.opacity : 0
              }}
            >
              {customPauseText || 'Pause'}
            </animated.span>
          </button>
        </section>
      </section>
    </VideoPlayerControlsStyles>
  );
}

export default Inject((store, ownProps) => ({
  isTabletLandscapeDown: store.isTabletLandscapeDown
}))(VideoPlayerControls);
