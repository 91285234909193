import {
  observable, decorate, computed, action
} from 'mobx';
import { createContext } from 'react';
import { Debounce } from '../lib/Utils';

class AppStore {
  constructor() {
    this.isInverse = false;
    this.isFilled = false;
    this.isHeaderAnimated = false;
    this.lastScrollTop = 0;
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.scrollDirection = 'down';
    this.ticking = false;
    const [html, body] = [document.documentElement, document.body];
    this.viewportW = window.innerWidth || html.clientWidth || body.clientWidth || window.screen.availWidth;
    this.viewportH = window.innerHeight || html.clientHeight || body.clientHeight || window.screen.availHeight;
    window.addEventListener('scroll', this.scrollHandler);
  }

  setInverse = val => {
    this.isInverse = val;
  };

  setFill = val => {
    this.isFilled = val;
  };

  setHeaderAnimated = val => {
    this.isHeaderAnimated = val;
  };

  scrollHandler = () => {
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.ticking = true;
        this.onScroll();
      });
    }
  };

  onScroll = () => {
    this.ticking = false;
    if (this.lastScrollTop < this.scrollTop) {
      this.scrollDirection = 'down';
    } else if (this.lastScrollTop > this.scrollTop) {
      this.scrollDirection = 'up';
    }
    this.lastScrollTop = this.scrollTop;
  };

  handleResize = () => {
    const [html, body] = [document.documentElement, document.body];
    const width = Math.max(
      window.innerWidth || html.clientWidth || body.clientWidth || window.screen.availWidth
    );
    const height = Math.max(
      window.innerHeight || html.clientHeight || body.clientHeight || window.screen.availHeight
    );
    this.setDimensions(width, height);
  };

  setDimensions = (width, height) => {
    this.viewportW = width;
    this.viewportH = height;
    this.getDeviceState();
  };
}

decorate(AppStore, {
  isInverse: observable,
  scrollTop: observable,
  isFilled: observable,
  isHeaderAnimated: observable,
  viewportW: observable,
  viewportH: observable,
  setInverse: action,
  setHeaderAnimated: action,
  setFill: action,
  setDimensions: action
});

export default createContext(new AppStore());
