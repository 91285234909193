// ****************************************/
// Video Block
// ****************************************/

import styled from 'styled-components';
import { font, media, underline } from '../Styles';

const VideoBlockStyles = styled.section`
  text-align: center;

  & video {
    border-radius: 1px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    ${media.tabletLandscapeAndBelow`
			object-fit: contain;
			
		`}
  }

  ${media.tabletLandscapeAndBelow`
		position: fixed;
    width: 100%;
    height: 100%;
	`}

  figure {
		height: 100vh;
    ${media.tabletLandscapeAndBelow`
			background-size: contain;
		`}
  }
`;

export default VideoBlockStyles;
