// ****************************************/
// Layer
// ****************************************/

import styled from 'styled-components';
import { font, media } from '../Styles';

const LayerStyles = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  clip-path: inset(0 0 100% 0);
  pointer-events: none;
  cursor: default;
  z-index: 4;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 3.3rem 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  ${media.tabletLandscapeAndBelow`
		padding: 21px 23px;
	`}

  &.is-active {
    clip-path: inset(0 0 0% 0);
    pointer-events: auto;
  }

  h1,
  p,
  button {
    font-family: ${font.family};
    font-size: ${font.h1};
    color: black;
    letter-spacing: 0.04rem;
    ${media.smallDesktopAndBelow`
	 		font-size: ${font.h1Tablet};
			 letter-spacing: 0.4px;
	 	`};
    ${media.tabletLandscapeAndBelow`
	 		font-size: ${font.h1Mobile};
	 	`};
  }

  button {
    position: relative;
    background: none;
    cursor: pointer;
    border: 0;
    top: -0.3rem;
    ${media.smallDesktopAndBelow`
			top: -3px;
		`}
  }

  button:focus {
    outline: none;
  }

  & > section {
    flex: 1;
  }

  .header-main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .header-main h1,
  .header-main p {
    text-transform: uppercase;
  }

  .header-main h1 + p {
    margin-top: 0.2rem;
    ${media.smallDesktopAndBelow`
			margin-top: 3px;
		`}
  }

  .header-main p + p {
    margin-top: 3rem;
    ${media.smallDesktopAndBelow`
			margin-top: 30px;
		`}
  }

  .header-main h1,
  .header-main p {
    flex: 1;
  }

  .header-info {
    text-align: right;
  }

  .copyright {
    position: absolute;
    bottom: 3.3rem;
    right: 4rem;
    ${media.tabletLandscapeAndBelow`
			bottom: 21px;
			right: 23px;
		`}
  }
`;

export default LayerStyles;
