import React, { useState, useRef } from 'react';
import LayerStyles from './Layer.styled';

function Layer({ isActive, toggle }) {
  const elem = useRef();
  return (
    <LayerStyles className={`${isActive ? 'is-active' : ''}`}>
      <section className="header-main">
        <h1>Sam Wilson:</h1>
        <p>Art Direction.</p>
        <p>
          Pictures, words.
          <br />
          moving, Still.
          <br />
          digital, print.
        </p>
        <p>
          <a
            href="https://www.instagram.com/samwilson.london/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram.
          </a>
          <br />
          <a href="mailto:studio@samwilson.london?subject=Enquiry">Contact</a>
        </p>
      </section>
      <section className="header-info">
        <button
          onClick={e => {
            if (!isActive) {
              elem.current.scroll({
                top: 0
              });
            }
            toggle();
          }}
        >
          close
        </button>
      </section>
      <section className="copyright">
        <p>{`Copyright Sam Wilson ${new Date().getFullYear()}`}</p>
      </section>
    </LayerStyles>
  );
}

export default Layer;
