// ****************************************/
// Typography
// ****************************************/
//
// p {
// 	font-size: ${font.p};
// 	${media.smallDesktopAndBelow`
// 		font-size: ${font.pMobile};
// 	`};
// };
//

const font = {
  family: 'Penna Sans, sans-serif',
  h1: '3.6rem',
  h1Tablet: '34px',
  h1Mobile: '24px'
};

export default font;
