import React, { Fragment, useState } from 'react';
import HeaderStyles from './Header.styled';
import Layer from '../Layer';

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const toggle = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <HeaderStyles>
        <section className="header-main">
          <h1>Sam Wilson:</h1>
          <p>Art Direction.</p>
        </section>
        <section className="header-info">
          <button
            onClick={e => {
              toggle();
            }}
          >
            information
          </button>
        </section>
      </HeaderStyles>
      <Layer isActive={isActive} toggle={toggle} />
    </>
  );
};

export default Header;
