// ****************************************/
// Header
// ****************************************/

import styled from 'styled-components';
import { font, media } from '../Styles';

const HeaderStyles = styled.nav`
  position: fixed;
  width: 100%;
  padding: 3.3rem 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  ${media.tabletLandscapeAndBelow`
		padding: 21px 23px;
	`}

  h1,
  p,
  button {
    font-family: ${font.family};
    font-size: ${font.h1};
    color: black;
    letter-spacing: 0.04rem;
    ${media.smallDesktopAndBelow`
	 		font-size: ${font.h1Tablet};
			 letter-spacing: 0.4px;
	 	`};
    ${media.tabletLandscapeAndBelow`
	 		font-size: ${font.h1Mobile};
	 	`};
  }

  button {
    position: relative;
    background: none;
    cursor: pointer;
    border: 0;
    top: -0.2rem;
    ${media.smallDesktopAndBelow`
			top: -2px;
		`}
  }

  button:focus {
    outline: none;
  }

  & > section {
    flex: 1;
  }

  .header-main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .header-main h1,
  .header-main p {
    text-transform: uppercase;
  }

  .header-main p {
    margin-top: 0.2rem;
    ${media.smallDesktopAndBelow`
			margin-top: 3px;
		`}
  }

  .header-main h1,
  .header-main p {
    flex: 1;
  }

  .header-info {
    text-align: right;
  }
`;

export default HeaderStyles;
