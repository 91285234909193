import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VideoBlockStyles from './VideoBlock.styled';
import Video from '../Video';

const VideoBlock = React.forwardRef((props, ref) => {
  const {
    vimeoLink, hasMargin, alignment, videoCaption
  } = props;

  const rawMarkup = text => {
    const markup = text;
    return { __html: markup };
  };

  return (
    <VideoBlockStyles
      className={`video-block ${hasMargin ? 'has-margin' : ''} ${alignment && alignment}`}
      ref={ref}
    >
      <Video
        posterFillMode={'cover'}
        link={vimeoLink}
        hd={true}
        controls={false}
        autoplay={true}
        loop={true}
      />
      {videoCaption ? (
        <p dangerouslySetInnerHTML={videoCaption && rawMarkup(videoCaption.content)} />
      ) : null}
    </VideoBlockStyles>
  );
});

export default VideoBlock;
