import React, { useEffect } from 'react';
import FilePlayer from 'react-player/lib/players/FilePlayer';
import { Video } from './Video.styled';

function VideoPlayerVideo({
  isPlaying, elem, url, isMuted, hlsConfig
}) {
  useEffect(() => {}, []);

  return (
    <Video>
      <FilePlayer
        url={url}
        playing={isPlaying}
        ref={elem}
        width={'100%'}
        height={'100%'}
        loop={true}
        controls={false}
        muted={isMuted}
        playsinline={true}
        wrapper={'section'}
        config={{
          file: {
            hlsOptions: hlsConfig
          }
        }}
      />
    </Video>
  );
}

export default VideoPlayerVideo;
