// ****************************************/
// Video
// ****************************************/

import styled from 'styled-components';

const FigureStyles = styled.figure`
  position: relative;
  width: 100%;
  height: 100%;

  &.is-filled video {
    border-radius: 1px;
    object-fit: cover;
  }
`;

const Video = styled.section`
  video {
    position: absolute;
    top: 0;
    left: 0;
    perspective: 1000;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    min-height: 1px;
    ::-webkit-media-controls {
      display: none;
    }
  }

  &.not-autoplay {
    opacity: 0;
  }
`;

export { FigureStyles, Video };
