import React, { useState, useEffect, useRef } from 'react';
import VideoPlayerControls from './VideoPlayerControls';
import { IsNumeric } from '../../lib/Utils';
import useIntersectionObserver from '../../lib/useIntersectionObserver';
import VideoPlayerVideo from './VideoPlayerVideo';

const allowedPlay = false;
const hasAttachedSrc = false;

function VideoPlayer({
  inView,
  url,
  controls,
  autoplay,
  hd,
  poster,
  customPlayText,
  customPauseText,
  playOnHover
}) {
  const elem = useRef();
  const container = useRef();
  let hideUITimeout;
  let playTimeout;

  const hlsConfig = {
    fragLoadingTimeOut: 5000,
    fragLoadingMaxRetry: 2,
    fragLoadingRetryDelay: 2,
    maxBufferLength: hd ? 10 : 2,
    startLevel: hd ? 4 : -1,
    loadLevel: -1
  };

  const attributes = {
    loop: true,
    playsInline: autoplay === true,
    muted: true,
    defaultMuted: true
  };

  const config = {
    rootMargin: '10% 10% 10% 10%',
    threshold: 0
  };

  const isActive = useIntersectionObserver({ elem: container, config });
  const [needsPlay, setNeedsPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(autoplay && !playOnHover);
  const [isControlsPlaying, setIsControlsPlaying] = useState(autoplay);
  const [isMuted, setIsMuted] = useState(true);

  const togglePlay = () => {
    if (!isControlsPlaying) {
      play();
      return;
    }
    pause();
  };

  const toggleAutoPlay = doUnMute => {
    if (!isControlsPlaying && isActive) {
      play();
      if (doUnMute) {
        unMute();
      }
      return;
    }
    if (!isActive) {
      pause();
    }
  };

  useEffect(() => {
    if (!elem.current) {
    }
    if (!autoplay) {
      return;
    }
    toggleAutoPlay();
  }, [isActive]);

  const play = () => {
    const video = elem.current.getInternalPlayer();
    video.play();
    setIsControlsPlaying(true);
  };

  const pause = () => {
    const video = elem.current.getInternalPlayer();
    video.pause();
    setIsControlsPlaying(false);
  };

  const mute = () => {
    const video = elem.current.getInternalPlayer();
    video.muted = true;
  };

  const unMute = () => {
    const video = elem.current.getInternalPlayer();
    video.muted = false;
  };

  const onMouseEnter = () => {
    if (!playOnHover) {
      return;
    }
    play();
  };

  const onMouseLeave = () => {
    if (!playOnHover) {
      return;
    }
    pause();
  };

  return (
    <section
      ref={container}
      onTouchStart={() => {
        onMouseEnter();
      }}
      onMouseEnter={() => {
        onMouseEnter();
      }}
      onMouseLeave={() => {
        onMouseLeave();
      }}
      onTouchEnd={() => {
        onMouseLeave();
      }}
    >
      <VideoPlayerVideo
        elem={elem}
        isPlaying={isPlaying}
        url={url}
        isMuted={isMuted}
        hlsConfig={hlsConfig}
      />
      {controls && (
        <VideoPlayerControls
          togglePlay={() => {
            togglePlay({ doUnMute: true });
          }}
          isPlaying={isControlsPlaying}
          customPlayText={customPlayText}
          customPauseText={customPauseText}
          isActive={isActive}
        />
      )}
    </section>
  );
}

export default React.memo(VideoPlayer);
