import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useIntersectionObserver from '../../lib/useIntersectionObserver';
import { IsNumeric } from '../../lib/Utils';
import VideoPlayer from './VideoPlayer';
import { FigureStyles } from './Video.styled';

function Video(props) {
  const {
    inView,
    hd,
    poster,
    posterFillMode,
    posterPosition,
    url,
    autoplay,
    controls,
    alignment,
    threshold,
    fill,
    placeholder,
    customPlayText,
    customPauseText,
    playOnHover
  } = props;
  let observer;
  const elem = useRef();
  const config = {
    rootMargin: '1000px',
    threshold: threshold !== undefined ? threshold : 0
  };
  const isOneTimeActive = useIntersectionObserver({ elem, config, isOnce: true });

  if (!url || !poster) {
    return null;
  }

  const dimensions = poster.match(/(\d+)x(\d+)/);
  if (!dimensions || !dimensions.length) {
    return null;
  }

  const width = parseInt(dimensions[1], 10);
  const height = parseInt(dimensions[2], 10);

  let paddingBottom;

  if (IsNumeric(width) && IsNumeric(height)) {
    paddingBottom = `${(height / width) * 100}%`;
  }

  const Figure = styled(FigureStyles)`
    padding-bottom: ${paddingBottom || 'initial'};
    background: url(${poster});
    background-size: ${posterFillMode || 'cover'};
    background-repeat: no-repeat;
    background-position: ${posterPosition || 'center'};
  `;

  return (
    <Figure
      ref={elem}
      className={`video-wrapper ${alignment && alignment} ${fill ? 'is-filled' : ''}`}
    >
      <VideoPlayer
        inView={inView}
        isOneTimeActive={isOneTimeActive}
        hd={hd}
        poster={!placeholder ? poster : null}
        url={url}
        autoplay={autoplay}
        controls={controls}
        customPlayText={customPlayText}
        customPauseText={customPauseText}
        playOnHover={playOnHover}
      />
    </Figure>
  );
}

export default React.memo(Video);
