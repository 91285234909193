import React from 'react';
import { Helmet } from 'react-helmet';
import Fetch from '../../lib/useFetch';

const Meta = () => (
  <Fetch
    href="/api/meta.json"
    method="GET"
    render={data => {
      console.log(data);
      return (
        <Helmet>
          <meta charset="UTF-8" />
          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
          <meta content="ie=edge" http-equiv="X-UA-Compatible" />
          <title>Sam Wilson</title>
          {/* <link rel="apple-touch-icon" sizes="180x180" href={images[0].url} />
          <link rel="icon" type="image/png" sizes="32x32" href={images[1].url} />
          <link rel="icon" type="image/png" sizes="16x16" href={images[2].url} /> */}
        </Helmet>
      );
    }}
  />
);

export default Meta;
