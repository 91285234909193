// ****************************************/
// Home
// ****************************************/

import styled from 'styled-components';
import { font, media } from '../Styles';

const HomeStyles = styled.section`
  overflow-y: scroll;
  position: relative;
`;

export default HomeStyles;
